import React from 'react';
import { Routes, Route } from "react-router-dom";
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { auth, analytics } from '../../firebase';
import RestaurantList from '../RestaurantList';
import Maps from '../Maps';
import Message from '../Message';
import Terms from '../Terms';
import Home from '../Home';
import NotFound from '../NotFound';

function App(props) {

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          type: prefersDarkMode ? 'dark' : 'light',
        },
      }),
    [prefersDarkMode],
  );

  const [user, setUser] = React.useState(null);
  const [init, setInit] = React.useState(false);

  auth.onAuthStateChanged((user) => {
    setUser(user);
    setInit(true);
  }, (error) => {
    setInit(true);
    console.log(error);
  });

  analytics.logEvent('App');

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        <Route path="/restaurants" element={<RestaurantList user={user} init={init} theme={theme} />} />
        <Route path="/maps" element={<Maps user={user} init={init} theme={theme} />} />
        <Route path="/message" element={<Message user={user} init={init} theme={theme} />} />
        <Route path="/terms" element={<Terms user={user} init={init} theme={theme} />} />
        <Route path="/" element={<Home user={user} init={init} theme={theme} />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </ThemeProvider>
  );

}

export default App;
