import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { firestore } from '../../firebase';
import Header from '../Header';
import Footer from '../Footer';
import Restaurant from '../Restaurant'

function RestaurantList(props) {
    const theme = props.theme;
    const user = props.user;
    const init = props.init;
    const [loading, setLoading] = useState(true);
    const [docs, setDocs] = useState({});
    const useStyles = makeStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            // minHeight: '100vh',
        },
        content: {
            padding: theme.spacing(3, 0)
        }
    });
    const classes = useStyles();

    if (theme && user && init && loading) {
        setLoading(false);
        firestore.collection('restaurants').onSnapshot({
            // Listen for document metadata changes
            includeMetadataChanges: false
        }, function (querySnapshot) {
            const arr = {};
            querySnapshot.forEach(function (doc) {
                // console.log(doc.data());
                arr[doc.id] = doc.data();
            });
            console.log(arr);
            setDocs(arr);
        });
    }

    return (
        <div className={classes.root}>
            <Header user={user} init={init} theme={theme} />
            <main className={classes.content}>
                <Container maxWidth="xl">
                    <Grid container spacing={3}>
                        {Object.keys(docs).map((key) => {
                            return <Grid item xs={12} md={4} sm={2} key={key}><Restaurant theme={theme} eid={key} data={docs[key]} /></Grid>
                        })}
                    </Grid>
                </Container>
            </main>
            <Footer theme={theme} />
        </div>
    );

}

export default RestaurantList;
