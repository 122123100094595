import React /*useEffect*/ from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { makeStyles } from "@mui/styles";
import Header from "../Header";
import Footer from "../Footer";

function Terms(props) {
  const theme = props.theme;
  const useStyles = makeStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
    },
    content: {
      padding: theme.spacing(4, 0, 8),
    },
  });
  const classes = useStyles();

  const user = props.user;
  const init = props.init;

  const title = "プライバシーポリシー 利用規約 運営者情報 - まめ２";

  // title
  document.title = title;

  // description
  document
    .querySelector('meta[name="description"]')
    .setAttribute("content", title);

  return (
    <div className={classes.root}>
      <Header user={user} init={init} theme={theme} />
      <main className={classes.content}>
        <Container>
          <Typography variant="h5" component="h2" sx={{ my: 2 }} id="privacy">
            プライバシーポリシー
          </Typography>
          <Typography gutterBottom>
            本サービスにおいて，以下のとおりプライバシーポリシーを定めます。個人情報を取り扱うにあたっては、
            「個人情報の保護に関する法律」を
            はじめとする個人情報の保護に関する法令、
            ガイドラインおよび本プライバシーポリシーを遵守いたします。また本サービスでは
            Google が提供する アクセス解析サービス Google Analytics
            を利用してアクセス情報を収集しています。 お使いのブラウザに Cookie
            を設定したり、既存の Cookie を読み取ることがあります。 Google
            によるデータ使用については
            <Link href="https://www.google.com/intl/ja/policies/privacy/partners/">
              https://www.google.com/intl/ja/policies/privacy/partners/
            </Link>
            をご参照ください。
          </Typography>
          <Typography variant="h6" component="h3" sx={{ my: 2 }}>
            広告の配信について
          </Typography>
          <Typography gutterBottom>
            当サイトは Google 及び Google
            のパートナー（第三者配信事業者）の提供する広告を設置しております。その広告配信には
            Cookie
            を使用し、当サイトやその他のサイトへの過去のアクセス情報に基づいて広告を配信します。
            Google が広告 Cookie
            を使用することにより、当サイトや他のサイトにアクセスした際の情報に基づいて、
            Google やそのパートナーが適切な広告を表示しています。 お客様は
            Google アカウントの広告設定ページ{" "}
            <Link href="https://adssettings.google.com/u/0/authenticated">
              https://adssettings.google.com/u/0/authenticated
            </Link>{" "}
            で、パーソナライズ広告を無効にできます。また aboutads.info
            のページにアクセスして頂き、パーソナライズ広告掲載に使用される第三者配信事業者の
            Cookie を無効にできます。 その他、 Google の広告における Cookie
            の取り扱い詳細については、 Google のポリシーと規約ページ{" "}
            <Link href="https://policies.google.com/technologies/ads">
              https://policies.google.com/technologies/ads
            </Link>{" "}
            をご覧ください。
          </Typography>
          <Typography variant="h5" component="h2" sx={{ my: 2 }}>
            利用規約
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            最終更新日 : 2020年12月5日
          </Typography>
          <Typography align="justify" gutterBottom>
            このサービス（以下「本サービス」）をご利用いただきありがとうございます。
            本サービスを利用することにより、本規約に同意することになります。以下を注意してお読みください。
            本サービスを不正に利用しないでください。本サービスで提供している情報、
            リンク先などによりいかなる損失や損害などの被害が発生しても、本サービスでは責任を負いません。
          </Typography>
          <Typography variant="h5" component="h2" sx={{ my: 2 }} id="admin">
            運営者情報
          </Typography>
          <Typography align="justify" gutterBottom>
            このWebサイトは日本で働くシステムエンジニア「mame2」が個人で運営しています。
          </Typography>
        </Container>
      </main>
      <Footer theme={theme} />
    </div>
  );
}

export default Terms;
