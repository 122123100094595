import React from "react";
import {
  AppBar,
  //   Avatar,
  //   Button,
  //   Dialog,
  //   DialogActions,
  //   DialogContent,
  //   DialogContentText,
  //   DialogTitle,
  Drawer,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
// import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MenuIcon from "@mui/icons-material/Menu";
// import GoogleIcon from "@mui/icons-material/Google";
import HomeIcon from "@mui/icons-material/Home";
// import MessageIcon from "@mui/icons-material/Message";
import AnnouncementIcon from "@mui/icons-material/Announcement";
// import ExitToAppIcon from "@mui/icons-material/ExitToApp";
// import authentication from "../../services/authentication";
import PolicyIcon from "@mui/icons-material/Policy";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";

function Header(props) {
  const theme = props.theme;
  const useStyles = makeStyles({
    list: {
      width: 250,
    },
    icon: {
      marginRight: theme.spacing(1),
    },
  });
  const classes = useStyles();
  //   const init = props.init;
  //   const user = props.user;
  //   const [loginDialog, setLoginDialog] = React.useState(false);
  //   const [logoutDialog, setLogoutDialog] = React.useState(false);
  const [left, setLeft] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setLeft(open);
  };

  //   const signInClick = () => {
  //     setLoginDialog(true);
  //   };

  //   const logoutClick = () => {
  //     setLogoutDialog(true);
  //   };

  //   const loginDialogClose = () => {
  //     setLoginDialog(false);
  //   };
  //   const logoutDialogClose = () => {
  //     setLogoutDialog(false);
  //   };

  //   const googleSignIn = () => {
  //     setLoginDialog(false);
  //     authentication.googleSignIn();
  //   };

  //   const logout = () => {
  //     authentication.signOut();
  //     setLogoutDialog(false);
  //   };

  return (
    <AppBar position="static">
      <Toolbar>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <IconButton
              color="inherit"
              onClick={toggleDrawer(true)}
              aria-label="メニューを開く"
            >
              <MenuIcon />
            </IconButton>
          </Grid>
          {/* {init &&
                        <Grid item>
                            {user &&
                                <IconButton onClick={logoutClick}>
                                    <Avatar src={user.photoURL} />
                                </IconButton>
                            }
                            {!user &&
                                <IconButton onClick={signInClick}>
                                    <Avatar />
                                </IconButton>
                            }
                        </Grid>
                    } */}
        </Grid>
        {/* <Dialog
          open={loginDialog}
          onClose={loginDialogClose}
          aria-labelledby="login-dialog-title"
          aria-describedby="login-dialog-description"
        >
          <DialogTitle id="login-dialog-title">
            {"ログインしますか？"}
          </DialogTitle>
          <DialogContent id="login-dialog-description">
            <Button onClick={googleSignIn} variant="contained" color="primary">
              <GoogleIcon className={classes.icon} />
              Googleアカウントでログイン
            </Button>
          </DialogContent>
          <DialogActions>
            <Button onClick={loginDialogClose} color="primary">
              いいえ
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={logoutDialog}
          onClose={logoutDialogClose}
          aria-labelledby="logout-dialog-title"
          aria-describedby="logout-dialog-description"
        >
          <DialogTitle id="logout-dialog-title">
            {"ログアウトしますか？"}
          </DialogTitle>
          <DialogContent>
            {user && <Avatar src={user.photoURL} />}
            <DialogContentText id="logout-dialog-description">
              このサービスからログアウトします
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={logoutDialogClose} color="primary">
              いいえ
            </Button>
            <Button onClick={logout} color="primary" autoFocus>
              はい
            </Button>
          </DialogActions>
        </Dialog> */}
        <Drawer open={left} onClose={toggleDrawer(false)}>
          <List className={classes.list}>
            <ListItem
              component={Link}
              href="/"
              color="textPrimary"
              underline="none"
            >
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText>ホーム</ListItemText>
            </ListItem>
            {/* {user && (
              <ListItem
                button
                component={Link}
                href="/message"
                color="textPrimary"
                underline="none"
              >
                <ListItemIcon>
                  <MessageIcon />
                </ListItemIcon>
                <ListItemText>メッセージを保存</ListItemText>
              </ListItem>
            )} */}
            <ListItem
              component={Link}
              href="/terms#privacy"
              color="textPrimary"
              underline="none"
            >
              <ListItemIcon>
                <PolicyIcon />
              </ListItemIcon>
              <ListItemText>プライバシーポリシー</ListItemText>
            </ListItem>
            <ListItem
              component={Link}
              href="/terms"
              color="textPrimary"
              underline="none"
            >
              <ListItemIcon>
                <AnnouncementIcon />
              </ListItemIcon>
              <ListItemText>利用規約</ListItemText>
            </ListItem>
            <ListItem
              component={Link}
              href="/terms#admin"
              color="textPrimary"
              underline="none"
            >
              <ListItemIcon>
                <SupervisorAccountIcon />
              </ListItemIcon>
              <ListItemText>運営者情報</ListItemText>
            </ListItem>
            {/* {user && (
              <ListItem button onClick={logoutClick}>
                <ListItemIcon>
                  <ExitToAppIcon />
                </ListItemIcon>
                <ListItemText>ログアウト</ListItemText>
              </ListItem>
            )}
            {!user && (
              <ListItem button onClick={signInClick}>
                <ListItemIcon>
                  <AccountCircleIcon />
                </ListItemIcon>
                <ListItemText>ログイン</ListItemText>
              </ListItem>
            )} */}
          </List>
        </Drawer>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
