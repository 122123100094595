import React, { useState, useEffect } from 'react';
import { Button, CircularProgress, Container, InputBase, Paper, Snackbar, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Header from '../Header';
import Footer from '../Footer';
import { firestore, functions } from '../../firebase';

function Message(props) {
    console.log(" --- Message --- ");
    const theme = props.theme;
    const useStyles = makeStyles({
        content: {
            padding: theme.spacing(4, 0, 8)
        },
        root: {
            display: 'flex',
            flexDirection: 'column',
            // minHeight: '100vh'
        },
        textBox: {
            marginTop: 'auto',
        },
        inputBox: {
            padding: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            width: '100%',
        },
        input: {
            marginLeft: theme.spacing(1),
            flex: 1,
        },
        iconButton: {
            padding: 10,
        },
        divider: {
            height: 28,
            margin: 4,
        }
    });
    const classes = useStyles();

    const user = props.user;
    const init = props.init;

    const [sendtext, setSendtext] = useState('');
    const [rcvMsg, setRcvMsg] = useState('');
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        console.log(' --- useEffect --- ');
        document.title = 'メッセージを保存 - まめ２';
    });

    const handleOnChange = (e) => {
        setSendtext(e.target.value);
    }

    const send = () => {
        const callable = functions.httpsCallable('addMessage');
        callable({ message: sendtext });
        setOpen(true);
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    if (theme && user && init && loading) {
        console.log(' --- unsubscribe ---');
        setLoading(false);
        firestore.collection('users').doc(user.uid)
            .onSnapshot({
                // Listen for document metadata changes
                includeMetadataChanges: true
            }, function (querySnapshot) {
                console.log(' --- querySnapshot ---');
                setRcvMsg(querySnapshot.data().message);
            });
    }

    return (
        <div className={classes.root}>
            <Header user={user} init={init} theme={theme} />
            <main className={classes.content}>
                <Container>
                    {user &&
                        <Typography>{rcvMsg}</Typography>
                    }
                    {!user &&
                        <CircularProgress />
                    }
                </Container>
            </main>
            {user &&
                <div className={classes.textBox}>
                    <Container>
                        <Paper className={classes.inputBox}>
                            <InputBase className={classes.input} placeholder="メッセージを入力します" value={sendtext} onChange={e => handleOnChange(e)} />
                            <Button className={classes.iconButton} onClick={send} variant="contained" color="primary">送信</Button>
                        </Paper>
                        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} message={"「" + sendtext + "」送りました！"} />
                    </Container>
                </div>
            }
            <Footer theme={theme} />
        </div>
    );

}

export default Message;
