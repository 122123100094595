import React from 'react';
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Rating from '@mui/material/Rating';

function Restaurant(props) {
    const theme = props.theme;
    // const eid = props.eid;
    const data = props.data;
    const useStyles = makeStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            // minHeight: '100vh',
        },
        content: {
            padding: theme.spacing(4, 0)
        },
        media: {
            height: 140,
        }
    });
    const classes = useStyles();

    return (
        <Card>
            <CardHeader title={data.name} subheader={data.city} />
            <CardMedia className={classes.media} image={data.photo} />
            <CardContent>
                <Rating name="test" value={data.avgRating}></Rating>
            </CardContent>
        </Card>
    );

}

export default Restaurant;
