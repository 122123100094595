import React from 'react';
import { analytics } from '../../firebase';

function NotFound() {
    analytics.logEvent('not_found');
    return (
        <React.Fragment>Not Found</React.Fragment>
    );
}

export default NotFound;