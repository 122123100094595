import React from "react";
import { makeStyles } from "@mui/styles";
import { Container, Grid, Link, Typography } from "@mui/material";

function Footer(props) {
  const theme = props.theme;
  const useStyles = makeStyles({
    footer: {
      padding: theme.spacing(3, 2),
      // marginTop: 'auto',
      // backgroundColor:
      //     theme.palette.type === 'dark' ? theme.palette.grey[800] : theme.palette.grey[200],
    },
  });

  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <Container>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item xs={6} sm={3}>
            <Typography variant="body2">
              <Link href="/terms#privacy">プライバシーポリシー</Link>
            </Typography>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Typography variant="body2">
              <Link href="/terms">利用規約</Link>
            </Typography>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Typography variant="body2">
              {"© "}
              <Link href="/terms#admin">mame2</Link> {new Date().getFullYear()}
              {"."}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
}

export default Footer;
