import React from 'react';
import { makeStyles } from '@mui/styles';
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import Header from '../Header';
import Footer from '../Footer';

function Maps(props) {

    const theme = props.theme;
    const user = props.user;
    const init = props.init;

    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
    };

    const useStyles = makeStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
        },
        content: {
            padding: theme.spacing(4, 0, 8)
        }
    });

    const classes = useStyles();

    const center = {
        lat: -3.745,
        lng: -38.523
    };

    const [map, setMap] = React.useState(null);

    const onLoad = React.useCallback(function callback(map) {
        const bounds = new window.google.maps.LatLngBounds();
        map.fitBounds(bounds);
        setMap(map)
    }, []);

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, []);

    return (
        <LoadScript googleMapsApiKey="AIzaSyDWQVMsn8hEVM4pYQh_OxrhKyPRVKhCBJw">
            <div className={classes.root}>
                <Header user={user} init={init} theme={theme} />
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={10}
                    onLoad={onLoad}
                    onUnmount={onUnmount}
                >
                    { /* Child components, such as markers, info windows, etc. */}
                    <></>
                </GoogleMap>
                <Footer theme={theme} />
            </div>
        </LoadScript>
    );
}

export default Maps;