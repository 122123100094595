import React, { useState } from "react";

import { storage, functions } from "../../firebase";
import {
  Alert,
  Box,
  Button,
  // Card,
  // CardActions,
  // CardContent,
  Container,
  // CircularProgress,
  // Grid,
  FormControl,
  Link,
  Modal,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
// import { useFormControl } from "@mui/material/FormControl";

import Header from "../Header";
import Footer from "../Footer";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const RECAPTCHA_SITE_KEY = "6LcRCSspAAAAACltCGb-nVtGfslH3kaJ1BIA_24D";

function Home(props) {
  const [open, setOpen] = useState(false);
  const [text, setText] = useState("");
  const [userValid, setUserValid] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [msg, setMsg] = useState("ありがとうございました");
  const theme = props.theme;
  const useStyles = makeStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      // minHeight: '100vh',
    },
    content: {
      padding: theme.spacing(4, 0),
    },
  });

  const user = props.user;
  const init = props.init;
  const classes = useStyles();

  const onChange = (event) => {
    // console.log(event, "onChange");
    const txt = document.getElementById("textField").value;
    setText(txt);
  };

  const handleClick = (event) => {
    event.preventDefault();
    window.grecaptcha?.enterprise.ready(async () => {
      const token = await window.grecaptcha.enterprise.execute(
        RECAPTCHA_SITE_KEY,
        { action: "CLICK" }
      );
      const callable = functions.httpsCallable("getAssessment");
      callable({ token: token }).then((resp) => {
        const assessment = resp.data;
        if (assessment >= 0.5) {
          setUserValid(true);
        }
      });
    });

    const val = document.getElementById("textField").value;
    let txt = "";
    const segmenterJa = new Intl.Segmenter(
      "ja-JP" /* { granularity: "word" } */
    );
    const segments = segmenterJa.segment(val);
    const arr = Array.from(segments);
    for (const a of arr) {
      txt += a.segment;
    }
    setText(txt);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleSnackBarClose = () => setSnackBarOpen(false);

  const handleSend = () => {
    setOpen(false);
    const storageRef = storage.ref();
    const d = new Date();
    const fName =
      "messages/d" +
      d.getFullYear() +
      ("00" + (d.getMonth() + 1)).slice(-2) +
      ("00" + d.getDate()).slice(-2) +
      ("00" + d.getHours()).slice(-2) +
      ("00" + d.getMinutes()).slice(-2) +
      ("00" + d.getSeconds()).slice(-2) +
      ".txt";
    const ref = storageRef.child(fName);
    ref
      .putString(text)
      .then((resp) => {
        document.getElementById("textField").value = "";
        setText("");
        setSnackBarOpen(true);
      })
      .catch((e) => {
        setSeverity("error");
        setMsg("失敗しました。🙇");
        setSnackBarOpen(true);
        console.log(e);
      });
  };

  return (
    <div className={classes.root}>
      <Header user={user} init={init} theme={theme} />
      <main>
        <Container>
          <Typography sx={{ pt: 4, pb: 4 }}>
            {/*align="justify" gutterBottom */}
            <Link href="https://www.odpt.org/">
              公共交通オープンデータセンター
            </Link>
            のオープンデータを利用したWebサイト「
            <Link href="https://t2.mame2.link/">バス運行情報</Link>
            」を公開しました。
          </Typography>
          <Box>
            <FormControl fullWidth>
              <TextField
                id="textField"
                autoFocus
                label="ご意見・ご要望"
                multiline
                minRows="12"
                // inputProps={{ maxLength: 2048 }}
                onChange={onChange}
              ></TextField>
              <Button
                variant="contained"
                sx={{ mt: 2 }}
                onClick={handleClick}
                disabled={text.length < 1}
              >
                送信
              </Button>
            </FormControl>
          </Box>
          <Typography sx={{ pt: 4, pb: 4 }}>
            次は来年の2月、先は長いな、、、
          </Typography>
          <Snackbar
            open={snackBarOpen}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            autoHideDuration={6000}
            onClose={handleSnackBarClose}
          >
            <Alert
              onClose={handleSnackBarClose}
              severity={severity}
              sx={{ width: "100%" }}
            >
              {msg}
            </Alert>
          </Snackbar>
          {/* <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Card>
                <CardContent>
                  <Typography>メッセージの保存</Typography>
                  {!user && (
                    <Typography variant="body2" color="textSecondary">
                      ログインが必要です
                    </Typography>
                  )}
                </CardContent>
                <CardActions>
                  <Button
                    component={Link}
                    href="/message"
                    disabled={!user}
                    underline="none"
                  >
                    保存
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid> */}
        </Container>
      </main>
      <Footer theme={theme} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-title" variant="h6" component="h2">
            確認
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2 }}>
            {text}
          </Typography>
          <Stack sx={{ mt: 2 }}>
            <Alert severity="info">送信してもよろしいですか？</Alert>
          </Stack>
          <Stack
            sx={{ mt: 2 }}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Button
              variant="contained"
              onClick={handleSend}
              disabled={!userValid}
            >
              {!userValid && "確認中"}
              {userValid && "送信"}
            </Button>
            <Button variant="text" onClick={handleClose}>
              閉じる
            </Button>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}

export default Home;
